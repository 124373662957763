<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" :gutter="20" align="middle">
          <span class="text">{{$t('subject.position')}}:</span>
          <el-select v-model="filter.position" :placeholder="$t('please_select')">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <el-button type="primary" @click="handleClick('add')">{{
          $t("btnTip.add")
        }}</el-button>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
          <el-button
              type="primary"
              @click="outExeAll('subjectList')"
              >전체출력</el-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
        <template v-slot:status="slotProps">
          <el-tag :type="statusTag(slotProps.callback.row.status)">
            {{ status(slotProps.callback.row.status) }}
          </el-tag>
        </template>
        <template v-slot:is_hot="slotProps">
          <el-tag :type="is_hotTag(slotProps.callback.row.is_hot)">
            {{ is_hot(slotProps.callback.row.is_hot) }}
          </el-tag>
        </template>
        <template v-slot:cover="slotProps">
          <div>
            <img width="100%" :src="slotProps.callback.row.cover" alt />
          </div>
        </template>
        <template v-slot:operation="slotProps">
          <el-dropdown trigger="click" @command="handleCommand" class="mr10">
            <el-button type="primary" size="mini">
              {{$t('subject.copy')}}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <div v-for="item in options" :key="item.value">
                <el-dropdown-item v-if="item.value" :command="{subject_id:slotProps.callback.row.id, position_id:item.value}">{{item.label}}</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('tToChapter')"
            placement="top"
          >
            <el-button
              @click="handleClick('chapter', slotProps.callback.row)"
              type="warning"
              size="mini"
            >{{$t('tToChapter')}}</el-button>
          </el-tooltip>
           <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').check"
            placement="top"
          >
            <el-button
              @click="handleClick('check', slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').edit"
            placement="top"
          >
            <el-button
              @click="handleClick('edit', slotProps.callback.row)"
              icon="el-icon-edit-outline"
              :type="'primary'"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              slotProps.callback.row.status === 0
                ? $t('subject.status1')
                : $t('subject.status0')
            "
            placement="top"
          >
            <el-button
              @click="handleClick('status', slotProps.callback.row)"
              :type="
                slotProps.callback.row.status === 0 ? 'success' : 'warning'
              "
              size="mini"
              >{{
                slotProps.callback.row.status === 0
                ? $t('subject.status1')
                : $t('subject.status0')
              }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="
              slotProps.callback.row.is_hot === 0
                ? $t('subject.is_hot1')
                : $t('subject.is_hot0')
            "
            placement="top"
          >
            <el-button
              @click="handleClick('is_hot', slotProps.callback.row)"
              :type="
                slotProps.callback.row.is_hot === 0 ? 'success' : 'warning'
              "
              size="mini"
              >{{
                slotProps.callback.row.is_hot === 0
                ? $t('subject.is_hot1')
                : $t('subject.is_hot0')
              }}</el-button
            >
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('btnTip').delete"
            placement="top"
          >
            <el-button
              @click="del(slotProps.callback.row.id)"
              type="danger"
              size="mini"
              >{{ $t("btnTip").delete }}</el-button
            >
          </el-tooltip>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { examTypeList, subjectList, deleteSubject, editSubject, copySubject } from "@/api/index";
export default {
  name: "subject",
  mixins: [mixin_list(subjectList)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
        ordering:"",
        position:"",
      },
      options:[

      ],
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        {
          label: this.$t("subject.create_time"),
          sortable: true,
          prop: "create_time",
          width:100
        },
        {
          label: this.$t("subject.title"),
          sortable: true,
          prop: "title",
        },
        {
          label: this.$t("subject.position"),
          sortable: true,
          prop: "position",
        },
        {
          label: this.$t("subject.chapter"),
          sortable: true,
          prop: "chapter",
          width:100
        },
        {
          label: this.$t("subject.examination"),
          sortable: true,
          prop: "examination",
          width:100
        },
        {
          label: this.$t("subject.order"),
          sortable: true,
          prop: "order",
          width:100
        },
        {
          label: this.$t("subject.cover"),
          sortable: true,
          prop: "cover",
          slot: "cover"
        },
        {
          label: this.$t("subject.status"),
          sortable: true,
          slot: "status",
          prop: "status",
          width:100
        },
        {
          label: this.$t("subject.is_hot"),
          sortable: true,
          slot: "is_hot",
          prop: "is_hot",
          width:100
        },
        {
          label: this.$t("operation"),
          prop: "",
          width: 550,
          slot: "operation",
        },
      ],
    };
  },
  computed: {},
  async created(){
    const res = await examTypeList({
        page:1,
        page_size:50
    })
    let options =  res.results.map((item)=>{
        return {
            value:item.id,
            label:item.title
        }
    })
    options.unshift({
        value:'',
        label:this.$t('all')
    })
    this.options = options
  },
  methods: {
    status(status) {
      switch (status) {
        case 0:
          return this.$t("subject.status0");
        case 1:
          return this.$t("subject.status1");
      }
    },
    statusTag(statusTag){
        switch (statusTag) {
        case 0:
          return 'warning';
        case 1:
          return 'success';
      }
    },
    is_hot(is_hot) {
      switch (is_hot) {
        case 0:
          return this.$t("subject.is_hot0");
        case 1:
          return this.$t("subject.is_hot1");
      }
    },
    is_hotTag(is_hotTag){
        switch (is_hotTag) {
        case 0:
          return 'warning';
        case 1:
          return 'success';
      }
    },
    async handleClick(type, data) {
      switch (type) {
        case "chapter":
          this.$router.push(`/category/subject/${data.id}/chapter?title=${data.title}`);
          break;
        case "check":
          this.$router.push(`/category/subject/detail?id=${data.id}`);
          break;
        case "add":
          this.$router.push(`/category/subject/edit`);
          break;
        case "status":
          await editSubject({
            ...data,
            status: data.status === 0 ? 1 : 0,
          });
          this.get_list();
          break;
          case "is_hot":
          await editSubject({
            ...data,
            is_hot: data.is_hot === 0 ? 1 : 0,
          });
          this.get_list();
          break;
        case "edit":
          this.$router.push(`/category/subject/edit?id=${data.id}`);
          break;
      }
    },
    async del(id) {
      this.$confirm(this.$t('sureDelete'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTips').submit,
        cancelButtonText: this.$t('btnTips').cancel,
        type: 'warning'
      }).then(async () => {
        await deleteSubject(id)
        this.get_list()
      }).catch({
      })
    },
    async handleCommand(command) {
      this.$confirm(this.$t('subject.sureCopy'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTips').submit,
        cancelButtonText: this.$t('btnTips').cancel,
        type: 'warning'
      }).then(async () => {
        await copySubject(command)
        this.get_list()
      }).catch({
      })
      return
      
    }
  },
};
</script>
<style lang="scss" scoped>
.text{
  width:100px;
  font-size: 16px;
}
.el-button{
  // padding:0;
}
</style>